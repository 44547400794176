import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { FirstbannerComponent } from './main/firstbanner/firstbanner.component';
import { NotificationComponent } from './notification/notification.component';
import { SecondbannerComponent } from './main/secondbanner/secondbanner.component';
import { FooterComponent } from './footer/footer.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ProductsComponent } from './products/products.component';
import { AppRoutingModule } from './app-routing.module';
import { TreatmentIndicationsComponent } from './products/treatment-indications/treatment-indications.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';


const appRoutes: Routes =[
  { path: 'aboutus', component: AboutusComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    FirstbannerComponent,
    NotificationComponent,
    SecondbannerComponent,
    FooterComponent,
    AboutusComponent,
    ProductsComponent,
    TreatmentIndicationsComponent,
    ContactUsComponent,
    PagenotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
