<div class="container-fluid">
  <div class="row">
    <div class="col-md text-center">
      <h1 style="margin-top: 50px; color:darkgrey">Page not found.</h1>
      <p style="color:darkgrey; margin-bottom: 100px;">
        The page you are looking for is not available.
      </p>
    </div>
  </div>
</div>
