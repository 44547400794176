<hr style="color: red; border-width: 2.px;">
<div style="background-color: lightgray; padding-bottom: 20px;">
<div class="container">
    <footer id="footer">
        <div class="row">
            <div class="col-md-12 text-center">
                <p class="h2">Evolucent, Inc</p> <br>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 text-center">
                Contact Numbers
                <hr>
                +63 32 2388380<br>
            </div>
            <div class="col-md-3 text-center">
                Email
                <hr>
                <a href="mailto:info@evolucent.tech" class="nav-link">info@evolucent.tech</a>
            </div>
            <div class="col-md-3 text-center">
                Social Media
                <hr>
                <div class="copyright">
                    <ul class="icons">

                        <li><a href="https://www.facebook.com/evolucent.ph/" class="icon fa-facebook" target="_blank"><span class="label">Facebook</span></a></li>

                    </ul>
                </div>
            </div>
            <div class="col-md-3 text-center">
                Useful Links
                <hr>
                <a href="https://photonxholdings.com" target="_blank" class="nav-link">PHOTONX Holdings</a>
            </div>
        </div>
        <br>

    </footer>
</div>
</div>
