<div class="container" style="margin-top: 20px;">

    <div class="row">
        <div class="col">

            <div class="card border-0">
                <div class="card-body">
                    Our devices consist of photonic energy sources, patient cables and patient applicators designed to deliver specific wavelengths of therapeutic light into well-studied access points and pathways within the body.

                </div>

            </div>

        </div>
    </div>

    <br>
    <div class="row">


        <div class="col-md-8">
            <div class="card rounded-0">
                <div class="card-header bg-transparent h4 evolucentblue text-center">Data Privacy</div>
                <div class="card-body text-justify">
                    <p>Evolucent, Inc. ("Evolucent", "we", "us", "our") respects your privacy, and is committed to protecting the privacy, confidentiality and security of the personal data you provide to us or that we collect about you when you use our website or our mobile application and other online products and services ("Site"), when you inquire about or request client services, or when you otherwise interact with us. We are aware of our responsibilities to protect your personal data, to keep it secure and comply with applicable privacy and data protection laws. Your continued use of this Site will constitute your expressed consent to the terms of this Privacy Policy.For your concerns regarding data privacy, you may reach our Data Privacy Officer at evolucent.ph@gmail.com</p>
                    <p>The confidentiality of your personal data are our ultimate concerns consistent with the requirements of the Data Privacy Act of 2012, its Implementing Rules and Regulations, National Privacy Commission issuances, and other relevant laws.
                        This statement provides in brief the manner we collect and process your personal data every time you visit our website, and avail of our services.</p>
                    <p>With your consent, we collect your personal data, depending on page(s) you visit, which may include your name address  and contact information in order to understand and to meet your needs and requirements and for purposes required by law.</p>
                    <p>We do not share your personal data with third parties except when you have expressedly directed to or consented to the sharing thereof or when required and/or permitted by law. In furtherance with our commitment to ensure the security of your personal data, reasonable and appropriate safeguards and measures have been put in place especially designed for its protection, and for the maintenance of its integrity, availability and confidentiality.</p>
                    <p>A cookie is a block of data that a web server places on a user's computer. Typically, it is used to ease navigation through the site. However, it is also a useful means of the website identifying the user, tracking the user's path through the site, and identifying repeat visits to the site by the same user (or same user's machine). This can then lead to a website owner being able to profile an individual user's browsing habits. Our website uses cookies to enhance your visit to our site and to enable us to know browsing habits so we can send you marketing advertisements for healthcare services. Only with your consent that we may collect personal data from cookies by agreeing to enable them and after which we may use this data only for the purpose of sending you our marketing advertisements of healthcare services.</p>
                    <p>Your effort to visit our website is truly appreciated. We promise to provide you a pleasant experience. Please fill in your information and inquiry below or send your communication to the links provided and we will get back to you at the soonest possible time.</p>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card rounded-0">
                <div class="card-header bg-transparent h4 evolucentblue text-center">Contact Us</div>
                <div class="card-body">
                    <ul class='list-group'>
                        <li class='list-group-item align-middle border-0'>
                            <div class="row">
                                <div class="col-2">
                                    <i class="material-icons evolucentblue" style="vertical-align: top;">email</i>
                                </div>
                                <div class="col-10"><a href="mailto:info@evolucent.tech">info@evolucent.tech</a></div>
                            </div>
                        </li>
                        <li class='list-group-item align-middle border-0'>
                            <div class="row">
                                <div class="col-2">
                                    <i class="material-icons evolucentblue" style="vertical-align: top;">phone</i>
                                </div>
                                <div class="col-10"><span> +63 32 2388380</span></div>
                            </div>
                        </li>
                        <li class='list-group-item align-middle border-0'>
                            <div class="row">
                                <div class="col-2">
                                    <i class="material-icons evolucentblue" style="vertical-align: top;">business</i>
                                </div>
                                <div class="col-10"><span> Evolucent, Inc.
                                    2/F Mactanland Bldg. Mactan Economic Zone-1
                                    Lapu-Lapu City 6015 Philippines. </span></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


