
<div style="padding-bottom: 50px;">
    <div class="container" >
        <div class="row" style="padding-top: 30px;">
            <div class="col-md-6 mb-4">
                <div class="card h-100">
                    <div class="card-header evolucentblue bg-white h3">Photonx In Pain Management</div>
                    <div class="card-body text-center">
                        <p>The Photonx Therapeutics Wavelengths Affect Both the Ascending and Descending Pain Transduction Pathways*</p>
                        <img src="../../../assets/images/pain-management.jpg" title="Pain management illustration" class="img-thumbnail border-0">
                        <p>Non-Immunosuppressant Mechanism*</p>
                        <p>Non-Habit Forming Therapy*</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-4">
                <div class="card h-100">
                    <div class="card-header evolucentblue bg-white h3">Photonx In Aesthetic Medicine</div>
                    <div class="card-body text-center">
                        <p>A Multi-Faceted Approach to Patient Care</p>
                        <img src="../../../assets/images/aesthetic-medicine.jpg" title="Aesthetic medicine illustration" class="img-thumbnail border-0">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Middle Text-->

<div class="container rounded">
    <div class="table bg-light border-bottom rounded" >
        <div class="row p-1 ">
            <div class="col text-center"><br>
                <p class=" evolucentblue h3">Treatment Indications</p>
            </div>
        </div>
        <div class="row p-3">
            <div class="col-md-6 text-center">
                <ul class="list-group">
                    <li class="list-group-item">Reduction of Pain</li>
                    <li class="list-group-item">Reduction of Inflammation</li>
                    <li class="list-group-item">Wound Healing</li>
                    <li class="list-group-item">Reduction of Pathogens in the Blood</li>
                </ul>
            </div>
            <div class="col-md-6 text-center">
                <ul class="list-group">
                    <li class="list-group-item">Immune System Modulation</li>
                    <li class="list-group-item">Improved ATP Synthesis</li>
                    <li class="list-group-item">Improved Oxygen Transport</li>
                    <li class="list-group-item">Improved Circulation</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!--Bottom Portion-->
<div style="padding-bottom: 50px;">
    <div class="container" >
        <div class="row" style="padding-top: 30px;">
            <div class="col-md-6 mb-4">
                <div class="card h-100">
                    <div class="card-header evolucentblue bg-white h3">Photonx In Anti-Aging Medicine</div>
                    <div class="card-body text-center">
                        <p>The Photonx Therapeutics Wavelengths Affect Both the Ascending and Descending Pain Transduction Pathways*</p>
                        <img src="../../../assets/images/anti-aging-medicine.jpg" title="Anti-aging illustration" class="img-thumbnail border-0">
                        <p>Non-Immunosuppressant Mechanism*</p>
                        <p>Non-Habit Forming Therapy*</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-4">
                <div class="card h-100">
                    <div class="card-header evolucentblue bg-white h3">Photonx In Regenerative Medicine</div>
                    <div class="card-body text-center">
                        <p>Optimizing Stem Cell Therapy</p>
                        <img src="../../../assets/images/regenerative-medicine.jpg" title="Regenerative medicine illustration" class="img-thumbnail border-0">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
