<div style="background-color: #eff1f3; padding-bottom: 2em; ">
    <div style="height: 80px;"></div>
    <div class="container" >
        <div class="d-flex flex-row justify-content-center">
            <div class="p-12 text-center"><h1 class="evolucentblue">Why intravenous light therapy?</h1></div>
        </div>
        <p></p>
        <div class="d-flex flex-row justify-content-center">
            <div class="p-1 text-center"></div>          
            <div class="p-3 ">
                <div class="font-weight-bold">FOR AESTHETICS</div>
                <p style="margin-top: 20px;">Acute inflammation is endemic to nearly all aesthetic procedures, including facial laser treatments, liposuction, peels and surgery. The wavelengths emitted by the Photonx Treatment System are clinically proven to lower inflammation, which reduces pain and accelerates healing among post-op patients.</p>                
                
            </div>
            <div class="p-3 ">
                <div class="font-weight-bold">FOR ANTI-AGING</div>
                <p style="margin-top: 20px;">Chronic inflammation occurs as a result of pathogens in the bloodstream and increased levels of cortisol, which is triggered by everyday stress. To combat accelerated aging, Photonx's specific wavelengths have been clinically proven to reduce pathogens and lower harmful inflammation</p>
            </div>
            <div class="p-3 ">
                <div class="font-weight-bold">FOR REGENERATIVE</div>
                <p style="margin-top: 20px;">Following stem-cell infusions, the graft-versus-host response can cause significant patient discomfort. The wavelengths of the Photonx Treatment System reduce inflammation and modulate this immune system response while boosting ATP synthesis to improve the viability and survivability of stem cells.</p>
            </div>

            <div class="p-1 text-center"></div>
        </div> 
  
        
    </div>
</div>
