import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './products/products.component';
import { MainComponent } from './main/main.component';
import { TreatmentIndicationsComponent } from './products/treatment-indications/treatment-indications.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

const appRoutes: Routes = [
    { path: '', redirectTo:'/home', pathMatch: 'full' },
    { path: 'home', component: MainComponent },
    { path: 'products', component: ProductsComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'learn-more', component: TreatmentIndicationsComponent },
    { path:'404', component: PagenotfoundComponent},
    {path: '**', redirectTo: '/404'}
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})

export class AppRoutingModule{}
