<div style="padding-bottom: 50px;">
    <div class="container" >
        <div class="row" style="padding-top: 30px;">
            <div class="col-md-12 text-center">
                <h1 class="h1 evolucentblue" style="margin-top: 30px; margin-bottom: 30px;">Photonx Products</h1>
                <p>
                    Among the products we handle are those that consist of photonic energy sources, patient cables and patient applicators, which are designed to deliver specific wavelengths of therapeutic light into well-studied access points and pathways within the body.
                </p>

            </div>
        </div>
    </div>
</div>

<div style="padding-bottom: 50px; background-color: #eff1f3;">
    <div class="container" >
        <div class="row" style="padding-top: 30px;">
            <div class="col-md-4">
                <img src="../../assets/images/photonx-station.jpg" Title="Photonx Station" class="img-fluid">
            </div>
            <div class="col-md-8 text-right">
                <span class="h3 evolucentblue">Photonx Station</span>
                <p style="margin-top: 20px;">The Photonx Station generates the photonic energy and manages the treatment process. The Photonx Station contains an exclusive, patented optic engine that employs a cutting edge mirror matrix to capture a preset combination of light sources and direct it into the output port for delivery to the patient applicator.</p>

                 <p>   To ensure accurate dosing, the output is measured and calibrated prior to each patient session. Any variation in output is compensated for by the optic engine, prior to the Photonx Station proceeding to the patient session.</p>
            </div>

        </div>

        <hr>

        <div class="row" style="padding-top: 30px;">

            <div class="col-md-8">
                <span class="h3 evolucentblue">Photonx Patient Cable</span>
                <p style="margin-top: 20px;">The Photonx Patient Cable transmits the energy emitted from the calibrated output port and delivers it to the patient applicator. </p>
            </div>
            <div class="col-md-4 text-right">
                <img src="../../assets/images/photonx-patient-cable.jpg" title="Photonx Patient cable" class="img-fluid">
            </div>
        </div>

        <hr>

        <div class="row" style="padding-top: 30px;">
            <div class="col-md-4">
                <img src="../../assets/images/photonx-pea.jpg" title="Photonx Photonic Energy Applicator" class="img-fluid">
            </div>
            <div class="col-md-8 text-right">
                <span class="h3 evolucentblue">Photonx Photonic Energy Applicator</span>
                <p style="margin-top: 20px;">The Photonx Photonic Energy Applicator (PEA) is an intravascular device that receives energy from the Photonx Patient Cable and emits that energy directly into the vein. The PEA is designed to integrate with a standard 20 gauge intravenous catheter where it resides during the course of a treatment cycle. The PEA also integrates with a standard saline flush, which flows throughout the course of the treatment as well. The fluid emission ensures that the tip of the PEA remains clear and unobstructed during the active treatment phase. After a single use, the PEA is discarded along with the catheter lumen in a sharps container.</p>
            </div>

        </div>

        <hr>

        <div class="row" style="padding-top: 30px;">

            <div class="col-md-8">
                <span class="h3 evolucentblue">Photonx Monotube Sublingual Applicator</span>
                <p style="margin-top: 20px;">The Photonx Monotube Sublingual Applicator delivers multiple photonic wavelengths into the vascular bed of the sublingual region. The applicator has a single branch that targets the sublingual vasculature on a single side of the frenulum, the Dorsal Lingual artery and/or the Dorsal Lingual vein. </p>
            </div>
            <div class="col-md-4">
                <img src="../../assets/images/photonx-monotube.jpg" class="img-fluid">
            </div>
        </div>
    </div>
</div>
