<header class="sticky-top" style="height: 60px;background-color: white;">
  <nav class="navbar sticky-top navbar-expand-md navbar-light"  style="background-color: white;">
    <div class="container align-items-center">
      <a class="navbar-brand" routerLink="/home">
        <img src="../../assets/images/evolucent-logo-wide.png" alt=""></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/products" routerLinkActive="active">Products</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/contact-us" routerLinkActive="active">Contact Us</a>
          </li>
          
        </ul>
      </div>
    </div>
  </nav>
</header>
