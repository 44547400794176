<div style="background-color: #eff1f3; height: 700px; ">
    <div class="container d-flex h-100 align-self-center" >

            <div class="p4 align-self-center">
                <div class="text-muted">EVOLUCENT TECH</div>
                <h2 class="evolucentblue">The Latest injectable is</h2>
                <h1 class="evolucentblue text-bold" style="font-size:4em; font-weight: bold;">LIGHT</h1>
                <h3>Intravenous light therapy for inflammation abatement*
                </h3><br><p></p>
                <a routerLink="/learn-more"><div class="btn btn-small btn-primary">Learn More</div></a>
            </div>
            <div class="p8 align-self-center text-center">
                <img src="../../../assets/images/uvlrx-machine-circle-v2.png" title="UVLRx Machine" class="img-fluid" >
            </div>

    </div>
</div>
<div style="background-color: #142a39; height: fit-content;">
    <div class="container">
        <div class="table">
            <div class="row">
                <div class="col-md-4 text-center">
                    <img src="../../../assets/images/health-icon-white.png" title="Health icon" height="30px" style="margin-top: 10px;">
                    <p class="h3 text-white" style="margin-top: 8px; margin-bottom: 10px;">Safe</p>
                </div>
                <div class="col-md-4 text-center">
                    <img src="../../../assets/images/list-icon.png" title="List icon" height="30px" style="margin-top: 10px;">
                    <p class="h3 text-white" style="margin-top: 8px; margin-bottom: 10px;">Simple</p>

                </div>
                <div class="col-md-4 text-center">
                    <img src="../../../assets/images/heart-icon.png" title="Heart icon" height="30px" style="margin-top: 10px;">
                    <p class="h3 text-white" style="margin-top: 8px; margin-bottom: 10px;">Effective</p>
                </div>
            </div>
        </div>

    </div>

</div>
